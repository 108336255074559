import { buildURL } from '@core-ui/url';
import { PATCH } from '@/api/oceanApi';
import { setCurrentBoatConfig } from '@/app/actions';
import { responseError } from '@/app/sagas';
import { getBudgetApproveFlowEnabled, getCurrentBoatId } from '@/app/selectors';
import { showNotification } from 'app/actions';
import { BoatConfig, BoatUpdateSchema } from '@/generated';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { setHeaderControlsLoading } from './actions';

function* toggleBudgetApproveFlow() {
  try {
    yield put(setHeaderControlsLoading({ loading: true }));

    const boatId: string = yield select(getCurrentBoatId);
    const budgetApproveFlowActive: boolean = yield select(getBudgetApproveFlowEnabled);

    const url = buildURL(`/boats/${boatId}/config`);
    const body: BoatUpdateSchema = {
      is_budget_approve_flow: !budgetApproveFlowActive,
    };

    const updatedConfig: BoatConfig = yield call(PATCH, url, body);

    yield all([
      put(setCurrentBoatConfig(updatedConfig)),
      put(
        showNotification({
          variant: 'success',
          title: 'notification.success.text.expenses_validation_change',
        })
      ),
    ]);
  } catch (e) {
    yield call(responseError, e);
  } finally {
    yield put(setHeaderControlsLoading({ loading: false }));
  }
}

export default [takeLatest(actions.toggleBudgetApproveFlow, toggleBudgetApproveFlow)];
